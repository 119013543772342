<template>
  <div>
    <div class="card">
      <div class="card-header has-background-primary">
        <p class="card-header-title has-text-white">
          Nr zamówienia: {{ this.order.uid }}
        </p>
        <div class="is-flex is-align-items-center pr-2">
          <company-order-status-updater
            :order="order"
          ></company-order-status-updater>
        </div>
      </div>
      <div class="card-content">
        <div class="block">
          Zamawiający: {{ this.order.user.email }}
          <div class="columns p-2">
            <div class="column is-half">
              <div>Dane osobowe</div>
              <div>
                Imię i nazwisko:
                <strong
                  >{{ order.user.details.first_name }}
                  {{ order.user.details.last_name }}</strong
                >
              </div>
              <div>
                Adres:
                <strong>
                  {{ order.user.address[0].local_nb }}
                  {{ order.user.address[0].street }},
                  {{ order.user.address[0].city }}
                  {{ order.user.address[0].postcode }}
                </strong>
              </div>
              <div>
                Telefon:
                <strong>
                  {{ order.user.details.phone }}
                </strong>
              </div>
              <div>
                Email:
                <strong>
                  {{ order.user.email }}
                </strong>
              </div>
            </div>
          </div>
        </div>
        <div class="block">
          Termin:
          <b-tag type="is-primary" size="is-medium">{{
            this.order.booking_time
          }}</b-tag>
        </div>
        <div class="block">
          Wartość zamówienia:
          <b-tag type="is-primary" size="is-medium"
            >{{ this.order.summary_value }} zł</b-tag
          >
        </div>
        <div class="block">
          Status:
          <b-tag type="is-primary" size="is-medium">{{
            orderStatus.name || '-'
          }}</b-tag>
        </div>
        <hr />
        <div class="block">
          <h1 class="title is-6">Zamówione usługi:</h1>
          <b-table :data="order.services" striped detailed>
            <b-table-column field="type" label="Usługa" v-slot="props">
              {{ props.row.type.name || '-' }}
            </b-table-column>
            <b-table-column field="name" label="Wariant" v-slot="props">
              {{ props.row.name || '-' }}
            </b-table-column>
            <b-table-column field="value" label="Cena" v-slot="props">
              {{ props.row.value }} zł
            </b-table-column>

            <template #detail="props">
              <div>
                {{ props.row.type.description }}
              </div>
            </template>
          </b-table>
        </div>
        <div class="col ml-3 mt-6">
          <!--v-if="doesOrderHistoryExists"-->
          <div class="">Historia zamówienia</div>
          <div class="block"></div>
          <hr />
          <div class="column is-full">
            <b-table :data="order.history" striped>
              <b-table-column
                field="order_status_id"
                label="Status"
                v-slot="props"
              >
                {{ orderStatusList[props.row.order_status_id].name  || '-'}}
              </b-table-column>
              <b-table-column field="comment" label="Komentarz" v-slot="props">
                {{ props.row.comment || "-" }}
              </b-table-column>
              <b-table-column
                field="created_at"
                label="Data zmiany"
                v-slot="props"
              >
                {{
                  props.row.created_at.split("T")[0] +
                  " " +
                  props.row.created_at.split("T")[1].split(".")[0]
                }}
              </b-table-column>
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CompanyOrderStatusUpdater from "@/components/company/CompanyOrderStatusUpdater";
//import { mapGetters } from "vuex";

export default {
  components: {
    "company-order-status-updater": CompanyOrderStatusUpdater,
  },
  data() {
    return {
        orderStatusList: [
    { id: 1, name: "Zarejestrowano" },
    { id: 2, name: "W realizacji" },
    { id: 3, name: "Uwagi" },
    { id: 4, name: "Zrealizowano" },
    { id: 5, name: "Problem" },
    { id: 6, name: "Zamówienie opłacone" },
  ],
    };
  },

  created() {
    this.$store
      .dispatch("companyOrders/getOrderById", this.$route.params.id)
      .then((response) => {
        response;
        //console.log(response);
      })
      .catch((err) => {
        console.error(err);
        //console.log(err);
      })
      .finally();
  },

  computed: {
    //...mapGetters("companyOrders", ["getOrderStatusById"]),
    order() {
      return this.$store.getters["companyOrders/getContextOrder"];
    },

    orderStatus() {
      return this.orderStatusList[this.order.order_status_id]
    },
  },
};
</script>