<template>
  <div>
    <b-button type="is-primary" inverted @click="isActive = true">
      Zmień status zamówienia
    </b-button>
    <b-modal
      v-model="isActive"
      has-modal-card
      :can-cancel="['escape', 'x', 'outside']"
      class="col is-6"
      :width="1200"
    >
      <section>
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">Zmień status zamówienia</p>
          </header>
          <section class="modal-card-body">
            <div class="tile is-12 is-vertical">
              <div class="tile">
                <b-field label="Status">
                  <b-select
                    placeholder="Status usługi"
                    v-model="statusId"
                    required
                  >
                    <option
                      v-for="option in status"
                      :value="option.id"
                      :key="option.id"
                    >
                      {{ option.name }}
                    </option>
                  </b-select>
                </b-field>
              </div>
              <div class="tile">
                <b-field label="Message">
                  <b-input
                    v-model="comment"
                    maxlength="200"
                    type="textarea"
                    expanded
                  ></b-input>
                </b-field>
              </div>
              <div class="tile">
                <b-button
                  type="is-primary"
                  expanded
                  rounded
                  @click="updateOrder"
                  >Zaktualizuj zamówienie</b-button
                >
                <b-button
                  type="is-dark"
                  class="ml-2"
                  expanded
                  rounded
                  @click="isActive = false"
                  >Anuluj</b-button
                >
              </div>
              <b-message
                v-if="error"
                title="Błąd aktualizacji zamówienia"
                aria-close-label="Close message"
              >
                {{ errorContent }}
              </b-message>
            </div>
          </section>
        </div>
      </section>
    </b-modal>
  </div>
</template>
<script>
export default {
  props: {
    order: Object,
  },
  data() {
    return {
      isActive: false,
      statusId: null,
      error: false,
      errorContent: "",
      comment: "",
      status: [
        { id: 1, name: "Zarejestrowano" },
        { id: 2, name: "W realizacji" },
        { id: 3, name: "Uwagi" },
        { id: 4, name: "Zrealizowano" },
        { id: 5, name: "Problem" },
        { id: 6, name: "Zamówienie opłacone" },
      ],
    };
  },

  methods: {
    updateOrder() {
      let payload = {
        comment: this.comment,
        order_status: this.statusId,
        companyId: this.companyId,
        orderId: this.order.uid,
      };
      this.$store
        .dispatch("companyOrders/updateOrderStatus", payload)
        .then((response) => {
          if (response.status == 201) this.isActive = false;
        })
        .catch((error) => {
          //console.log("error", error);
          this.error = true;
          this.errorContent = error;
        });
    },
    changeOrderStatus(newStatusId) {
      const payload = {
        orderId: this.order.uid,
        statusToSet: {
          order_status: newStatusId,
        },
      };
      this.$store
        .dispatch("companyOrders/updateOrderStatus", payload)
        .then((response) => {
          if (response.status === 201) {
            this.$buefy.notification.open({
              message: "Pomyślnie zmieniono status zamówienia",
              type: "is-primary",
              position: "is-top",
              duration: 10000,
            });
          } else {
            this.$buefy.notification.open({
              message: "Nie udało się zmienić statusu zamówienia",
              type: "is-danger",
              position: "is-top",
              duration: 10000,
            });
          }
        });
    },
  },

  computed: {
    companyId: {
      get() {
        return this.$store.state.auth.id;
      },
    },
    orderStatus() {
      if (!this.order) {
        return 0;
      }

      return this.$store.getters["companyOrders/getOrderStatusById"](
        this.order.order_status_id
      );
    },

    loading() {
      if (this.order) {
        return false;
      }

      return true;
    },
  },
};
</script>
<style lang="scss" scoped>
.modal .modal-card {
  width: 50%;
  min-width: 350px;
}
</style>